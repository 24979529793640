
.new-ticket {
  display: flex;
  justify-content: space-between;
  padding: 24px 32px;
  gap: 24px;
  background-color: #ececec;
  .middle-panel {
    .content {
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      padding: 15px;
      .tag {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 12px;
        color: #000000;
        span {
          color: #ff1c1c;
        }
      }
      .info {
        background: #ebecf6;
        border-radius: 8px;
        padding: 12px 16px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 15px;
        color: #000000;
      }
      .message {
        margin-bottom: 12px;
        color: #2e305c;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        span {
          color: #ff1c1c;
        }
      }
      .btns {
        margin: 10px 0 16px;
        a {
          text-decoration: none;
        }
        a,
        button {
          background: #0fae3b;
          border-radius: 4px;
          align-items: center;
          padding: 8px 16px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */

          text-transform: uppercase;

          color: #ffffff;

          @media screen and (max-width: 640px) {
            font-size: 12px;
          }
          &.save {
            background: #0fae3b;
            margin-right: 10px;
            &.disabled {
              opacity: 0.7;
              background: #73e693;
              cursor: not-allowed;
              pointer-events: none;
            }
          }
          &.cancel {
            background: #212237;
            margin-right: 10px;
            &.disabled {
              opacity: 0.7;
              background: #636483;
              cursor: not-allowed;
              pointer-events: none;
            }
          }
          &.mark-as-read {
            background: #2e305c;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 15px;
    background-color: #ffffff;
    .middle-panel {
      min-width: 100%;
      .content {
        border: none;
        border-radius: 0;
        .info {
          text-align: left;
        }
      }
    }
  }
}
